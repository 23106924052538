import React, { useState, useRef } from 'react';
import { gtag } from '../../helpers/gtag.js';
import { mq } from '../../theme';
import Measure from 'react-measure';
import { keyframes } from '@emotion/react';
import Container from '../Container/Container';
import BackgroundImage from '../BackgroundImage';
import PlayButton from '../PlayButton';
import CloseButton from '../CloseButton';
import Video from '../Video';

const ANIMATION_DURATION = 400;

const fadeIn = keyframes`
0% {
  opacity: 0;
  visibility: hidden;
}
100% {
  opacity: 1;
  visibility: visible;
}
`;

const fadeOut = keyframes`
0% {
  opacity: 1;
  visibility: visible;
}
100% {
  opacity: 0;
  visibility: hidden;
}
`;

const ContainerVideo = ({
  id,
  background,
  direction = 'right',
  ratio = [16, 9],
  source,
  placeholder,
  trackLabel,
  children
}) => {
  const playerRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);
  const [showVideo, setShowVideo] = useState(false);

  const handleClick = () => {
    gtag('event', 'Play', {
      event_category: 'Video',
      event_label: trackLabel
    });

    setShowVideo(true);
    playerRef.current.play();
  };

  const handleClose = () => {
    setShowVideo(false);
    playerRef.current.pause();
  };

  const computedRatio = ratio[0] / ratio[1];
  const pourcentRatio = (ratio[1] * 100) / ratio[0];

  return (
    <Container
      id={id}
      background={background}
      css={{
        flexDirection: 'column',
        minHeight: 700,
        justifyContent: 'center'
      }}
    >
      <div
        css={mq({
          boxSizing: 'border-box',
          marginLeft: [direction === 'left' ? '50%' : 0, 0, 0],
          marginRight: [direction === 'right' ? '50%' : 0, 0, 0],
          transition: `transform ${ANIMATION_DURATION}ms ease-in-out, filter ${ANIMATION_DURATION}ms linear`,
          filter: [showVideo ? 'blur(4px)' : 'none', 'none', 'none'],
          transform: [
            `translate3d(${direction === 'right' ? '-' : '+'}${
              showVideo ? contentHeight * computedRatio - 445 : 0
            }px, 0px, 0px)`,
            'none',
            'none'
          ]
        })}
      >
        {children}
      </div>
      <Measure
        bounds
        onResize={({ bounds: { height } }) => {
          if (height !== contentHeight) {
            setContentHeight(height);
          }
        }}
      >
        {({ measureRef }) => (
          <div
            ref={measureRef}
            css={mq({
              position: ['absolute', 'relative', 'relative'],
              right: direction === 'right' ? 0 : null,
              left: direction === 'left' ? 0 : null,
              top: 0,
              bottom: 0,
              transition: `width ${ANIMATION_DURATION / 2}ms ease-in-out`,
              width: [
                showVideo ? contentHeight * computedRatio : 445,
                '100%',
                '100%'
              ],
              marginTop: ['auto', 50, 50]
            })}
          >
            <div css={{ height: '100%' }}>
              <BackgroundImage
                src={placeholder}
                css={mq({
                  position: 'relative',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center center',
                  overflow: 'hidden',
                  height: ['100%', 0, 0],
                  paddingTop: ['auto', `${pourcentRatio}%`, `${pourcentRatio}%`]
                })}
              >
                <PlayButton
                  onClick={handleClick}
                  css={{
                    animation: `${
                      showVideo ? fadeOut : fadeIn
                    } ${ANIMATION_DURATION}ms normal forwards ease-in-out`
                  }}
                />
              </BackgroundImage>
              {
                <Video
                  ref={playerRef}
                  fluid={false}
                  width={contentHeight * computedRatio}
                  src={source}
                  css={{
                    outline: 'none',
                    top: 0,
                    left: 0,
                    position: 'absolute',
                    animation: `${
                      showVideo ? fadeIn : fadeOut
                    } ${ANIMATION_DURATION}ms normal forwards ease-in-out`
                  }}
                />
              }
            </div>

            <div
              role="button"
              tabIndex="0"
              onKeyPress={handleClose}
              onClick={handleClose}
              css={{
                position: 'absolute',
                top: '50%',
                left: direction === 'right' && -30,
                right: direction === 'left' && -30,
                animation: `${
                  showVideo ? fadeIn : fadeOut
                } ${ANIMATION_DURATION}ms normal forwards ease-in-out`,
                width: 60
              }}
            >
              <CloseButton />
            </div>
          </div>
        )}
      </Measure>
    </Container>
  );
};

export default ContainerVideo;
